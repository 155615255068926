import { createRouter, createWebHashHistory } from "vue-router";
import http from "@/service/common";
import App from "./App.vue";
import Print from "./layout/Print.vue";
import AuthService from "@/service/AuthService";
import { isMobile } from "@/utils/common";
const routes = [
  {
    path: "/admin",
    name: "admin",
    component: App,
    children: [
      {
        path: "users",
        name: "admin_users",
        component: () => import("@/pages/admin/user/CrudUsers.vue"),
      },
      {
        path: "partner",
        name: "admin_partner",
        component: () => import("@/pages/admin/partner/CrudPartners.vue"),
      },
      {
        path: "stores",
        name: "admin_stores",
        component: () => import("@/pages/admin/store/CrudStores.vue"),
      },
      {
        path: "manifestitem",
        name: "admin_manifestitem",
        component: () =>
          import("@/pages/admin/manifestitem/ManifestItemList.vue"),
      },
      /*
        {
          path: 'configs',
          name: 'admin_configs',
          component: () => import('@/pages/admin/config/CrudConfigs.vue')
        }
        */
    ],
  },
  {
    path: "/users",
    name: "users",
    component: App,
    children: [
      {
        path: "receipt/create",
        name: "users_receipt_create",
        component: () => import("@/pages/users/receipt/ReceiptForm.vue"),
        meta: {
          orgTypes: { owner: ["*"], agent: ["*"] },
        },
      },
      {
        path: "receipt/edit/:id?",
        name: "users_receipt_edit",
        component: () => import("@/pages/users/receipt/ReceiptForm.vue"),
        meta: {
          orgTypes: { owner: ["*"], agent: ["*"] },
        },
      },
      {
        path: "receipt/view/:id?",
        name: "users_receipt_view",
        component: () => import("@/pages/users/receipt/ReceiptView.vue"),
        meta: {
          orgTypes: { owner: ["*"], agent: ["*"] },
        },
      },
      {
        path: "receipt/list",
        name: "users_receipt_list",
        component: () => import("@/pages/users/receipt/ReceiptList.vue"),
        meta: {
          orgTypes: { owner: ["*"], agent: ["*"] },
        },
      },
      {
        path: "receipt/:receiptId/package/:packageId?",
        name: "users_receipt_package",
        component: () => import("@/pages/users/receipt/ReceiptPackageEdit.vue"),
        meta: {
          orgTypes: { owner: ["*"], agent: ["*"] },
        },
      },
      {
        path: "shipment",
        children: [
          {
            path: "list",
            name: "users_shipment_list",
            component: () => import("@/pages/users/warehouse/ShipmentList.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "detail/:id",
            name: "user_shipment_detail",
            component: () =>
              import("@/pages/users/warehouse/ShipmentPackage.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "import",
            name: "users_warehouse_import",
            component: () =>
              import("@/pages/users/warehouse/CameraArrivedPackage.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
        ],
      },
      {
        path: "warehouse",
        children: [
          {
            path: "receipt/edit/:shipmentId?",
            name: "warehouse_receipt_edit",
            component: () => import("@/pages/users/warehouse/ReceiptEdit.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            }
          },
          {
            path: "receipt/list",
            name: "warehouse_receipt_list",
            component: () => import("@/pages/users/warehouse/ReceiptList.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            }
          },
          {
            path: "deliverynote/create",
            name: "users_deliverynote_create",
            component: () =>
              import("@/pages/users/warehouse/DeliveryNoteCreate.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "deliverynote/edit/:id?",
            name: "users_deliverynote_edit",
            component: () =>
              import("@/pages/users/warehouse/DeliveryNoteEdit.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "deliverynote/list",
            name: "users_deliverynote_list",
            component: () => import("@/pages/users/warehouse/DeliveryNoteList.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            }
          }
        ],
      },
      {
        path: "package",
        children: [
          {
            path: "arriving",
            name: "user_package_arriving",
            component: () => import("@/pages/users/warehouse/ArrivingPackages.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "arrived",
            name: "user_package_arrived",
            component: () => import("@/pages/users/warehouse/ArrivedPackages.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
          {
            path: "infos/:id",
            name: "user_package_infos",
            component: () => import("@/pages/users/warehouse/PackageEdit.vue"),
            meta: {
              orgTypes: { owner: ["*"] },
            },
          },
        ]
      },
      {
        path: "issue",
        children: [
          {
            path: "list",
            name: "users_issue_list",
            component: () => import("@/pages/users/issue/IssueList.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "detail/:id",
            name: "users_issue_detail",
            component: () => import("@/pages/users/issue/IssueDetail.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "edit/:id?",
            name: "users_issue_edit",
            component: () => import("@/pages/users/issue/IssueEdit.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
        ]
      },
      {
        path: "delivery/camera",
        name: "users_delivery_camera",
        component: () => import("@/pages/users/delivery/DeliveryCamera.vue"),
        meta: {
          orgTypes: { owner: ["*"] },
        },
      },
    ],
  },
  {
    path: "/agent",
    name: "agent",
    component: App,
    children: [
      {
        path: "user",
        name: "agent_user",
        component: () => import("@/pages/partner/CrudPartnerUsers.vue"),
        meta: {
          orgTypes: { agent: ["admin"] },
        },
      },
      {
        path: "address/list",
        name: "agent_address_list",
        component: () => import("@/pages/users/address/AddressList.vue"),
        meta: {
          orgTypes: { owner: ["admin"], agent: ["*"] },
        },
      },
      {
        path: "address/import",
        name: "agent_address_import",
        component: () => import("@/pages/users/address/ImportAddressList.vue"),
        meta: {
          orgTypes: { owner: ["admin"], agent: ["*"] },
        },
      },
      {
        path: "package",
        children: [
          {
            path: "receipt",
            name: "agent_package_receipt",
            component: () =>
              import("@/pages/users/packing/UnpackedReceipt.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "edit/:receiptId?",
            name: "agent_package_edit",
            component: () => import("@/pages/users/packing/PackingForm.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "create/:shipmentId?",
            name: "agent_package_create",
            component: () => import("@/pages/users/packing/PackageCreate.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "list",
            name: "agent_package_list",
            component: () => import("@/pages/users/packing/PackageList.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "import",
            name: "agent_package_import",
            component: () => import("@/pages/users/packing/PackageImport.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          // {
          //     path: 'package/camera',
          //     name: 'agent_package_camera',
          //     component: () => import('@/pages/users/packing/AgentCamera.vue'),
          //     meta: {
          //       orgTypes: {'agent':['*']}
          //     }
          // },
        ],
      },
      {
        path: "shipment",
        children: [
          {
            path: "create",
            name: "agent_shipment_create",
            component: () => import("@/pages/users/shipment/ShipmentCreate.vue"),
            meta: {
              orgTypes: { owner: ["admin"], agent: ["*"] },
            },
          },
          {
            path: "edit/:id?",
            name: "agent_shipment_edit",
            component: () => import("@/pages/users/shipment/ShipmentEdit.vue"),
            meta: {
              orgTypes: { owner: ["admin"], agent: ["*"] },
            },
          },
          {
            path: "detail/:id",
            name: "agent_shipment_detail",
            component: () =>
              import("@/pages/users/shipment/ShipmentPackage.vue"),
            meta: {
              orgTypes: { owner: ["admin"], agent: ["*"] },
            },
          },
          {
            path: "document/:id?",
            name: "agent_shipment_document",
            component: () =>
              import("@/pages/users/shipment/ShipmentDocument.vue"),
            meta: {
              orgTypes: { owner: ["admin"], agent: ["*"] },
            },
          },
          {
            path: "list",
            name: "agent_shipment_list",
            component: () => import("@/pages/users/shipment/ShipmentList.vue"),
            meta: {
              orgTypes: { owner: ["admin"], agent: ["*"] },
            },
          },
          {
            path: "preview/:id?",
            name: "agentshipmentform",
            component: () => import("@/pages/users/shipment/ShipmentForm.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
          {
            path: "scanner/:id?",
            name: "agent_package_scanner",
            component: () =>
              import("@/pages/users/shipment/ShipmentScanPackage.vue"),
            meta: {
              orgTypes: { owner: ["*"], agent: ["*"] },
            },
          },
        ],
      },

      /*
        {
            path: 'camera',
            name: 'agent_camera',
            component: () => import('@/pages/agent/AgentCamera.vue'),
            meta: {
              orgTypes: {'agent':['*']}
            }
        }
        */
    ],
  },
  {
    path: "/partner",
    name: "partner",
    component: App,
    children: [
      {
        path: "user",
        name: "partner_user",
        component: () => import("@/pages/partner/CrudPartnerUsers.vue"),
        meta: {
          orgTypes: { owner: ["*"], partner: ["admin"] },
        },
      },
    ],
  },
  {
    path: "/print",
    name: "print",
    component: Print,
    children: [
      {
        path: "receipt/:id",
        name: "print_receipt",
        component: () => import("@/pages/print/ReceiptPreview.vue"),
        meta: {
          orgTypes: { agent: ["*"] },
        },
      },
      {
        path: "preview/shipingform/:resource/:id/:packageId?",
        name: "print_preview_shippingform",
        component: () => import("@/pages/print/ShippingFormPreview.vue"),
        meta: {
          orgTypes: { agent: ["*"] },
        },
      },
      {
        path: "preview/shipinglabel/:resource/:id/:packageId?",
        name: "print_preview_shippinglabel",
        component: () => import("@/pages/print/ShippingLabelPreview.vue"),
        meta: {
          orgTypes: { agent: ["*"] },
        },
      },
      {
        path: "preview/:resource/:id/:packageId?",
        name: "print_preview",
        component: () => import("@/pages/print/PrintPreview.vue"),
        meta: {
          orgTypes: { agent: ["*"] },
        },
      },
    ],
  },
  {
    path: "/",
    name: "app",
    component: App,
    children: [
      {
        path: "/:resource",
        name: "home",
        //component: () => import('@/pages/QRCodeEntry.vue'),
        component: () => import("./QRCode.vue"),
        meta: { public: true },
      },
      {
        path: "",
        name: "entry",
        component: () => import("./QRCode.vue"),
        meta: { public: true },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./pages/auth/Login.vue"),
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("./pages/LandingDemo.vue"),
  },
  {
    path: "/error",
    name: "error",
    component: () => import("./pages/Error.vue"),
  },
  {
    path: "/notfound",
    name: "notfound",
    component: () => import("./pages/NotFound.vue"),
  },
  {
    path: "/access",
    name: "access",
    component: () => import("./pages/Access.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
const getDefaultRoute = function (user) {
  if (isMobile()) {
    switch (user.orgType) {
      case "owner":
        return "users_warehouse_import";
      case "agent":
        return "agent_shipment_list";
      //return "agent_package_scanner";
      case "partner":
        return "partner_camera";
    }
    return "home";
  } else {
    switch (user.orgType) {
      case "owner":
        return "users_shipment_list";
      case "agent":
        return "users_receipt_list";
      case "partner":
        return "partner_camera";
    }
    return "home";
  }
};
router.beforeEach((to, from, next) => {
  const currentUser = AuthService.currentUser;
  //Logined user
  //alert(JSON.stringify(currentUser));
  if (currentUser) {
    http.defaults.headers.common["Content-Type"] = "application/json";
    http.defaults.headers.common["Authorization"] =
      "Bearer " + currentUser.accessToken;
    if (
      to.name != "login" &&
      to.name != "entry" &&
      AuthService.authenticate(currentUser, to)
    ) {
      next();
    } else {
      const defRoute = getDefaultRoute(currentUser);
      if (defRoute == to.name) {
        next();
      } else {
        next({ name: defRoute });
      }
    }
  } else {
    http.defaults.headers.common["Authorization"] = "";
    if (to.name == "login") {
      next();
    } else {
      if (to.path == "/") {
        next({ name: "login" });
      } else {
        next({ name: "login", query: { returnUrl: to.path } });
      }
    }
  }
});
export { getDefaultRoute };
export default router;
